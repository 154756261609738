import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { CommercialService } from '../../../../../services/commercial.service';
import { LeadDetailsComponent as ReusableLeadDetails } from '../../../../../reusable-components/lead-details/lead-details.component';
import { ConfirmDialog, ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { LoadingService } from '../../../../../services/loading.service';

@Component({
  selector: 'app-lead-details-commercial',
  standalone: true,
  imports: [
    ReusableLeadDetails,
    ConfirmDialogModule,
    ButtonModule
  ],
  templateUrl: './lead-details.component.html',
  styleUrl: './lead-details.component.sass'
})
export class LeadDetailsComponent implements OnInit, OnDestroy {

  leadDetails: any = {};

  @ViewChild('confirmDialogRef') confirmDialogRef!: ConfirmDialog;

  processingStatus: any[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    private confirmationService: ConfirmationService,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private commercialService: CommercialService
  ) { }

  ngOnInit(): void {
    const reference = this.route.snapshot.paramMap.get('reference')!;
    this.commercialService.getLeadDetails(reference)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.leadDetails = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });

    this.commercialService.getProcessingStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.processingStatus = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  getLoading() {
    return this.loadingService.get();
  }

  addNote(event: any) {
    this.commercialService.addNoteToLead(event.leadId, event.message)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.leadDetails.events = [...this.leadDetails.events, response];
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  confirmUpdateStatus(event: any) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      accept: () => {
        this.commercialService.updateLeadStatus(event.leadId, event.statusId)
          .pipe(takeUntil(this._destroy$))
          .subscribe({
            next: response => {
              this.leadDetails = response;
              this.messageService.add({ severity: 'success', detail: 'Statut mis à jour', life: 8000 });
            }, error: error => {
              this.messageService.add({ severity: 'error', detail: error.description });
            }
          });
      },
      reject: () => { }
    });
  }

  rejectUpdateStatus() {
    this.confirmDialogRef.reject();
  }

  acceptUpdateStatus() {
    this.confirmDialogRef.accept();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '../../../../../services/admin.service';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { InputTextModule } from 'primeng/inputtext';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { LoadingService } from '../../../../../services/loading.service';

@Component({
  selector: 'app-pending-payments',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    TagModule,
    InputTextModule,
    ButtonModule
  ],
  templateUrl: './pending-payments.component.html',
  styleUrl: './pending-payments.component.sass'
})
export class PendingPaymentsComponent implements OnInit, OnDestroy {

  pendingPayments: any[] = [];
  selectedPayments: any[] = [];

  bankCapability: string = "";
  disablePaymentButton: boolean = true;

  private _destroy$ = new Subject<void>();

  constructor(
    private adminService: AdminService,
    private messageService: MessageService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.adminService.getSponsorshipsPendingPayment()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.pendingPayments = response;

          if (response.length > 0 && response[0].company?.bridgeBank) {
            this.bankCapability = response[0].company?.bridgeBank.capability;
          }
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  getLoading() {
    return this.loadingService.get();
  }

  selectionChange() {
    if (this.selectedPayments.length === 0) {
      this.disablePaymentButton = true;
    } else if (this.selectedPayments.length > 1 && this.bankCapability === 'single_payment') {
      this.disablePaymentButton = true;
      this.messageService.add({ severity: 'warn', detail: 'Attention votre banque n\'accepte que les paiements uniques. Veuillez sélectionner un seul paiement' });
    } else {
      this.disablePaymentButton = false;
    }
  }

  confirmPayment() {
    var sponsorshipsId: number[] = [];
    this.selectedPayments.forEach(p => {
      sponsorshipsId.push(p.id);
    });

    this.adminService.createPayment(sponsorshipsId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          window.location.href = response.consent_url;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}

export class CheckCustomerRequest {
  
  companyId: number | null;
  email: string | null;

  constructor(companyId: number | null, email: string | null) {
    this.companyId = companyId;
    this.email = email;
  }

}
<app-lead-details [leadDetails]="leadDetails" [processingStatus]="processingStatus" [activeUser]="'commercial'" (addNoteEmitter)="addNote($event)" (changeStatusEmitter)="confirmUpdateStatus($event)"></app-lead-details>

<p-confirmDialog #confirmDialogRef>
    <ng-template pTemplate="message">
        <div class="flex flex-column align-items-center w-full gap-3">
            <p>Êtes-vous sur de vouloir mettre à jour le statut de ce lead ?</p>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="flex gap-2 justify-content-end">
            <p-button icon="pi pi-times" label="Annuler" [rounded]="true" [outlined]="true" severity="danger"
                (onClick)="rejectUpdateStatus()" />
            <p-button type="submit" icon="pi pi-save" [loading]="getLoading()"
                label="Mettre à jour" [rounded]="true" [outlined]="true" [disabled]="getLoading()"
                (onClick)="acceptUpdateStatus()" />
        </div>
    </ng-template>
</p-confirmDialog>

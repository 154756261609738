<div class="block">
    <div>
        <div class="text-2xl font-medium text-900 mb-3 p-2">Mes parrainages</div>

        <app-sponsorships [sponsorships]="sponsorships" [activeUser]="'customer'" (newSponsorshipEmitter)="showNewSponsorshipDialog()"></app-sponsorships>

    </div>
</div>

<p-dialog header="Parrainer un amis" [(visible)]="newSponsorshipDialogIsVisible" [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '50vw' }" [draggable]="false"
    [resizable]="false">

    <p-messages key="message-key-dialog-new-sponsorship" />
    
    <form [formGroup]="newSponsorshipForm" (ngSubmit)="onSubmitNewSponsorship()">
        <ul class="list-none p-0 m-0 mb-3">
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Entreprise</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <p-dropdown formControlName="company" [options]="customerCompanies" optionLabel="company.name"
                        placeholder="Choisir une entreprise" (onChange)="onChangeCompany($event)"></p-dropdown>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Commercial</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    {{ commercialFullName }}
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Email filleul</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Email" pInputText formControlName="godChildEmail"
                            aria-describedby="email-edit" (focusout)="focusOutGodChildEmail()">
                        <small class="text-red-600" id="email-edit"
                            *ngIf="newSponsorshipForm.controls.godChildEmail.errors?.['pattern']">
                            Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Nom filleul</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Nom" pInputText formControlName="godChildLastName"
                            aria-describedby="lastName-edit">
                        <small class="text-red-600" id="lastName-edit"
                            *ngIf="newSponsorshipForm.controls.godChildLastName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Prénom filleul</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Prénom" pInputText formControlName="godChildFirstName"
                            aria-describedby="firstName-edit">
                        <small class="text-red-600" id="firstName-edit"
                            *ngIf="newSponsorshipForm.controls.godChildFirstName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Téléphone filleul</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Téléphone" pInputText formControlName="godChildPhone"
                            aria-describedby="phone-edit">
                        <small class="text-red-600" id="phone-edit"
                            *ngIf="newSponsorshipForm.controls.godChildPhone.errors?.['pattern']">
                            Règles: 10 chiffres.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Ville</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Ville" pInputText formControlName="city"
                            aria-describedby="city-edit">
                        <small class="text-red-600" id="city-edit"
                            *ngIf="newSponsorshipForm.controls.city.errors?.['pattern']">
                            Règles: que des caractères alphanumériques, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Description du besoin</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <textarea pInputTextarea formControlName="description" aria-describedby="description-edit" maxlength="255"></textarea>
                        <small class="text-red-600" id="description-edit"
                            *ngIf="newSponsorshipForm.controls.description.errors?.['pattern']">
                            Règles: entre 10 et 255 caractères.
                        </small>
                    </div>
                </div>
            </li>
        </ul>

        <div class="flex flex-row gap-3">
            <div class="flex">
                <p-checkbox formControlName="legalNoticesAccepted" [binary]="true" />
            </div>
            <div class="flex">
                <p-scrollPanel [style]="{ width: '100%', height: '100px' }">
                    « Les informations recueillies par <span class="font-medium font-italic">LINKUPP</span> font l'objet d'un
                    traitement informatique ayant pour finalité la gestion de notre fichier clients / prospects, 
                    <span class="font-medium font-italic">LINKUPP</span> ayant un intérêt légitime à développer et entretenir une
                    relation commerciale avec ses clients / prospects. Nous vous assurons qu'aucune donnée
                    ne sera communiquée, cédée ni revendue à des tiers. Les données seront conservées pour
                    une durée maximale de 3 ans après la fin de la relation commerciale, ou après votre
                    dernière demande. Conformément à la loi « Informatique et Libertés » du 6 janvier 1978
                    modifiée et au Règlement Européen 2016/679 du 27 avril 2016 relatif à la protection des
                    personnes physiques à l'égard du traitement des données à caractère personnel, vous
                    disposez d'un droit d'accès et de rectification aux informations qui vous concernent ainsi que
                    d'un droit d'opposition, du droit à la limitation du traitement et à l'effacement dans le cadre
                    de la réglementation en vigueur. Vous pouvez exercer ces droits, il vous suffit d'envoyer un
                    email à cette adresse : <span class="font-medium font-italic">contact&#64;linkupp.fr</span>. Si vous estimez, après nous avoir contactés, que vos
                    droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation
                    à la CNIL. »
                </p-scrollPanel>
            </div>
        </div>

        <div class="flex gap-2 justify-content-end mt-5">
            <p-button icon="pi pi-times" label="Annuler" [rounded]="true" [outlined]="true" severity="danger"
                (onClick)="closeNewSponsorshipDialog()" />
            <p-button type="submit" icon="pi pi-save" [loading]="getLoading()"
                label="Envoyer" [rounded]="true" [outlined]="true"
                [disabled]="!newSponsorshipForm.valid || getLoading()" />
        </div>
    </form>
</p-dialog>
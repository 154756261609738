export class NewLeadRequest  {
  
  commercialId: number | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  source: string | null;
  city: string | null;

  constructor(commercialId: number | null, firstName: string | null, lastName: string | null, email: string | null, phone: string | null, source: string | null, city: string | null) {
    this.commercialId = commercialId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.source = source;
    this.city = city;
  }
}
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NewCompanyRequest } from '../payloads/requests/newCompanyRequest';
import { Observable } from 'rxjs';

const API = `${environment.rootApiUrl}/api/super-admin`;

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {

  constructor(
    private http: HttpClient
  ) { }

  createCompany(request: NewCompanyRequest): Observable<any> {
    return this.http.post(`${API}/companies`, request, { responseType: 'text' });
  }

  getAllCompanies(): Observable<any> {
    return this.http.get(`${API}/companies`);
  }

  getCompanyDetails(reference: string): Observable<any> {
    return this.http.get(`${API}/companies/details`,
      {
        params: {
          reference: reference
        }
      }
    );
  }
}

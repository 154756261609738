import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NewEmployeeRequest } from '../../../../../payloads/requests/newEmployeeRequest';
import { AdminService } from '../../../../../services/admin.service';
import { LoadingService } from '../../../../../services/loading.service';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { TableModule, TableRowSelectEvent } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MessagesModule } from 'primeng/messages';

@Component({
  selector: 'app-employees',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CheckboxModule,
    TooltipModule,
    FormsModule,
    ScrollPanelModule,
    MessagesModule
  ],
  templateUrl: './employees.component.html',
  styleUrl: './employees.component.sass'
})
export class EmployeesComponent implements OnInit, OnDestroy {

  employees: any[] = [];

  newEmployeeDialogIsVisible: boolean = false;

  newEmployeeForm = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    lastName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    firstName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    isAdmin: false,
    isCommercial: false,
    legalNoticesAccepted: [false, Validators.requiredTrue]
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private adminService: AdminService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.adminService.getAllEmployees()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.employees = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  getLoading() {
    return this.loadingService.get();
  }

  identifyRole(index: any, item: any) {
    return item.id
  }

  onRowSelectEmployee(event: TableRowSelectEvent) {
    const id = event.data.id;
    this.router.navigate([`portal/pro/admin/employee-details/${id}`]);
  }

  showNewEmployeeDialog() {
    this.newEmployeeForm.reset();
    this.newEmployeeDialogIsVisible = true;
  }

  atLeastOneRole(): boolean {
    const isAdmin: boolean = this.newEmployeeForm.get('isAdmin')?.value!;
    const isCommercial: boolean = this.newEmployeeForm.get('isCommercial')?.value!;

    return isAdmin || isCommercial ? true : false;
  }

  focusOutEmployeeEmail() {
    const emailControl = this.newEmployeeForm.get('email')!;
    if (emailControl.value && emailControl.valid) {
      this.adminService.checkEmployee(emailControl.value!.toLowerCase())
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: response => {
            // noting to do
          }, error: error => {
            this.newEmployeeForm.get('email')?.setValue('');
            this.newEmployeeForm.get('legalNoticesAccepted')?.setValue(false);

            if (error.status === 500) {
              this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-employee' });
            } else {
              this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-employee' });
            }
          }
        })
    }
  }

  onSubmitNewEmployee() {
    const request: NewEmployeeRequest = new NewEmployeeRequest(
      this.newEmployeeForm.get('lastName')?.value!,
      this.newEmployeeForm.get('firstName')?.value!,
      this.newEmployeeForm.get('email')?.value!,
      this.newEmployeeForm.get('phone')?.value!,
      this.newEmployeeForm.get('isAdmin')?.value!,
      this.newEmployeeForm.get('isCommercial')?.value!);
    this.adminService.addEmployee(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.closeNewEmployeeDialog();
          this.employees = [...this.employees, response];
          this.messageService.add({ severity: 'success', detail: 'Nouvel employé créé', life: 8000 });
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-employee' });
        }
      });
  }

  closeNewEmployeeDialog() {
    this.newEmployeeDialogIsVisible = false;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}

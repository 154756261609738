<div class="block">
    <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">
        <div class="flex p-2 mb-8">
            <div class="flex">
                <div class="flex flex-column">
                    <div class="relative mx-auto max-w-5rem md:max-w-10rem">
                        <img src="assets/images/web/user_details.png" class="w-full" alt="User picture" />
                    </div>
                    <div class="flex justify-content-center font-bold">
                        {{ employeeDetails.firstName }} {{ employeeDetails.lastName }}
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5">
            <div class="flex font-bold mb-2">
                COORDONNÉES
            </div>
            <div class="flex">
                {{ employeeDetails.phone }}
            </div>
            <div class="flex">
                {{ employeeDetails.email }}
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5">
            <div class="flex font-bold mb-2 gap-2">
                <span>
                    <i class="pi pi-info-circle" style="font-size: 0.8rem"
                        pTooltip="Modifier les rôles aura un impact sur l'expérience utilisateur, veuillez contacter le support pour en savoir plus."></i>
                </span>
                <span>ROLES</span>
                <span class="cursor-pointer" [hidden]="hiddenActionsRoles" (click)="updateRoles()">
                    <i class="pi pi-save" style="color: green"></i>
                </span>
                <span class="cursor-pointer" [hidden]="hiddenActionsRoles" (click)="revertRoles()">
                    <i class="pi pi-undo" style="color: red"></i>
                </span>
            </div>
            <div class="flex flex-column gap-2">
                <div class="flex gap-3">
                    <p-checkbox [(ngModel)]="isCommercial" [disabled]="disableIsCommercialCheckbox()"
                        (onChange)="onChangeCheckbox()" [binary]="true" inputId="binary" />
                    <span>
                        <i class="pi pi-briefcase"></i>
                    </span>
                    <span>Commercial</span>
                </div>
                <div class="flex gap-3">
                    <p-checkbox [(ngModel)]="isAdmin" [disabled]="disableIsAdminCheckbox()"
                        (onChange)="onChangeCheckbox()" [binary]="true" inputId="binary" />
                    <span>
                        <i class="pi pi-crown"></i>
                    </span>
                    <span>Administrateur</span>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '../../../../../services/admin.service';
import { CommonModule } from '@angular/common';
import { NewCustomerRequest } from '../../../../../payloads/requests/newCustomerRequest';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../../../../services/loading.service';
import { CheckCustomerRequest } from '../../../../../payloads/requests/checkCustomerRequest';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';

@Component({
  selector: 'app-customers',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    DialogModule,
    ScrollPanelModule,
    CheckboxModule,
    DropdownModule,
    MessagesModule
  ],
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.sass'
})
export class CustomersComponent implements OnInit, OnDestroy {

  customers: any[] = [];
  commercials: any[] = [];

  newCustomerDialogIsVisible: boolean = false;

  newCustomerForm = this.formBuilder.group({
    commercialId: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    lastName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    firstName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    legalNoticesAccepted: [false, Validators.requiredTrue]
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private adminService: AdminService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.adminService.getAllCustomers()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.customers = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  getLoading() {
    return this.loadingService.get();
  }

  showNewCustomerDialog() {
    this.newCustomerForm.reset();
    this.newCustomerDialogIsVisible = true;

    this.adminService.getAllCommercials()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.commercials = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-customer-admin' });
        }
      });
  }

  focusOutCustomerEmail() {
    const emailControl = this.newCustomerForm.get('email')!;
    if (emailControl.value && emailControl.valid) {
      const checkCustomerRequest: CheckCustomerRequest = new CheckCustomerRequest(null, emailControl.value!.toLowerCase());
      this.adminService.checkCustomer(checkCustomerRequest, false)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: response => {
            if (response) {
              this.newCustomerForm.patchValue({
                lastName: response.lastName,
                firstName: response.firstName,
                phone: response.phone
              });

              this.messageService.add({ severity: 'info', detail: 'Utilisateur trouvé. Si la correspondance n\'est pas bonne, vérifiez l\'email.', key: 'message-key-dialog-new-customer-admin' });
            }
          }, error: error => {
            this.newCustomerForm.get('email')?.setValue('');
            this.newCustomerForm.get('legalNoticesAccepted')?.setValue(false);

            if (error.status === 500) {
              this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-customer-admin' });
            } else {
              this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-customer-admin' });
            }
          }
        })
    }
  }

  onSubmitNewCustomer() {
    const request: NewCustomerRequest = new NewCustomerRequest(
      this.newCustomerForm.get('firstName')?.value!,
      this.newCustomerForm.get('lastName')?.value!,
      this.newCustomerForm.get('email')?.value!,
      this.newCustomerForm.get('phone')?.value!,
      +this.newCustomerForm.get('commercialId')?.value!);
    this.adminService.addCustomer(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.closeNewCustomerDialog();
          this.customers = [...this.customers, response];
          this.messageService.add({ severity: 'success', detail: 'Nouveau client créé', life: 8000 });
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-customer-admin' });
        }
      });
  }

  closeNewCustomerDialog() {
    this.newCustomerDialogIsVisible = false;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}

import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router, RouterModule } from '@angular/router';
import { LoginRequest } from '../../payloads/requests/loginRequest';
import { AuthenticationService } from '../../services/authentication.service';
import { JwtService } from '../../services/jwt.service';
import { LoadingService } from '../../services/loading.service';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { StyleClassModule } from 'primeng/styleclass';
import { PasswordModule } from 'primeng/password';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ButtonModule,
    InputTextModule,
    StyleClassModule,
    ReactiveFormsModule,
    PasswordModule,
    CommonModule,
    RouterModule,
    MessagesModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.sass'
})
export class LoginComponent implements AfterViewInit, OnDestroy {

  loginForm = this.formBuilder.group({
    usernameOrEmail: ['', Validators.required],
    password: ['', Validators.required]
  })

  private _destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private jwtService: JwtService,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private messageService: MessageService
  ) { }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParamMap
      .pipe(takeUntil(this._destroy$))
      .subscribe(paramMap => {
        if (!paramMap.has("error")) { return; }

        this.messageService.add({ severity: 'error', detail: paramMap.get("error")! });

        this.removeParamFromUrl(paramMap, ["error"]);
      })
  }

  getLoading() {
    return this.loadingService.get();
  }

  onSubmitLogin() {
    const request: LoginRequest = new LoginRequest(
      this.loginForm.get('usernameOrEmail')?.value!,
      this.loginForm.get('password')?.value!);
    this.authService.login(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.jwtService.setToken(response.jwtToken);

          if(this.jwtService.isSuperAdmin) {
            this.router.navigate(['portal/super-admin']);
          } else if(this.jwtService.isAdmin) {
            this.router.navigate(['portal/pro/admin']);
          } else if(this.jwtService.isCommercial) {
            this.router.navigate(['portal/pro/commercial']);
          } else if(this.jwtService.isCustomer) {
            this.router.navigate(['portal/customer']);
          }
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  removeParamFromUrl(paramMap: ParamMap, keysToRemove: string[]) {
    const queryParams: any = {};
    const keysToKeep = paramMap.keys.filter(k => !keysToRemove.includes(k));
    keysToKeep.forEach(k => (queryParams[k] = paramMap.get(k)));

    this.router.navigate([], { queryParams, replaceUrl: true, relativeTo: this.activatedRoute });
  }

  ngOnDestroy(): void {
      this._destroy$.next();
  }
  
}

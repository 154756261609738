export class NewCompanyRequest {

  name: string | null;
  siren: string | null;
  email: string | null;
  phone: string | null;
  adminEmail: string | null;
  adminLastName: string | null;
  adminFirstName: string | null;
  adminPhone: string | null;

  constructor(name: string | null, siren: string | null, email: string | null, phone: string | null, adminEmail: string | null, adminLastName: string | null, adminFirstName: string | null, adminPhone: string | null) {
    this.name = name;
    this.siren = siren;
    this.email = email;
    this.phone = phone;
    this.adminEmail = adminEmail;
    this.adminLastName = adminLastName;
    this.adminFirstName = adminFirstName;
    this.adminPhone = adminPhone;
  }
}
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterOutlet } from '@angular/router';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ThemeService } from '../../services/theme.service';
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { AvatarModule } from 'primeng/avatar';
import { JwtService } from '../../services/jwt.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    ButtonModule,
    StyleClassModule,
    RouterOutlet,
    AvatarModule,
    CommonModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.sass'
})
export class HeaderComponent implements OnInit {

  theme: string = 'saga-blue';
  
  constructor(
    private router: Router,
    private themeService: ThemeService,
    private jwtService: JwtService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    const theme: string = localStorage.getItem("linkupp_theme") || "";
    if (theme === 'saga-blue' || theme === 'arya-blue') {
      this.theme = theme;
      this.changeTheme(theme)
    }
  }

  goToMyAccount() {
    if (this.jwtService.isSuperAdmin) {
      this.router.navigate(['portal/super-admin']); 
    } else if (this.jwtService.isAdmin) {
      this.router.navigate(['portal/pro/admin']);
    } else if (this.jwtService.isCommercial) {
      this.router.navigate(['portal/pro/commercial']);
    } else if (this.jwtService.isCustomer) {
      this.router.navigate(['portal/customer']);
    } else {
      this.router.navigate(['portal']);
    }    
  }

  goToHomePage() {
    this.router.navigate(['home']);
  }

  changeTheme(theme: string) {
    localStorage.setItem("linkupp_theme", theme);
    this.theme = theme;
    this.themeService.switchTheme(theme);
  }

  showOnHomePage(): boolean {
    return this.router.url === '/home' ? true : false;
  }

  scrollIntoView(elem: string) {
    this.document.querySelector(elem)!.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  
}

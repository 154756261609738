<div class="block">
    <div>
        <div class="text-2xl font-medium text-900 mb-3 p-2">Parrainages en attente de paiement</div>

        <div class="flex justify-content-between mb-3 p-2" *ngIf="pendingPayments.length === 0">
            <div class="text-xl font-medium text-900">Aucun paiement trouvé...</div>
        </div>

        <p-table #dt1 [value]="pendingPayments" [(selection)]="selectedPayments" (selectionChange)="selectionChange()"
            [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack" [breakpoint]="'767px'" sortField="createdAt"
            [sortOrder]="-1" [globalFilterFields]="['reference', 'godFather.lastName', 'godFather.firstName', 'amount']"
            [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="pendingPayments.length > 0">

            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-end gap-2">
                    <p-button severity="success" icon="pi pi-euro" label="Payer" [rounded]="true" [text]="true"
                        (onClick)="confirmPayment()" [disabled]="disablePaymentButton" [loading]="getLoading()" />
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Chercher..." />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
                    <th pSortableColumn="reference">Référence<p-sortIcon field="reference" /></th>
                    <th pSortableColumn="godFather.lastName">Nom bénéficiaire<p-sortIcon field="godFather.lastName" />
                    </th>
                    <th pSortableColumn="godFather.firstName">Prénom bénéficiaire<p-sortIcon
                            field="godFather.firstName" /></th>
                    <th pSortableColumn="amount">Montant<p-sortIcon field="amount" /></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-sponsorship>
                <tr [pSelectableRow]="sponsorship">
                    <td>
                        <p-tableCheckbox [value]="sponsorship" />
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Référence:&nbsp;</span>
                        <span>
                            <a target="_blank"
                                [routerLink]="['/portal/pro/admin/sponsorship-details', sponsorship.reference]">
                                {{ sponsorship.reference }}&nbsp;<i class="pi pi-external-link"
                                    style="font-size: 0.6rem"></i>
                            </a>
                        </span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Nom bénéficiaire:&nbsp;</span>
                        <span>{{ sponsorship.godFather.lastName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Prénom bénéficiaire:&nbsp;</span>
                        <span>{{ sponsorship.godFather.firstName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Montant:&nbsp;</span>
                        <span>{{ sponsorship.amount }},00€</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
</div>
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommercialService } from '../../../../../services/commercial.service';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SponsorshipDetailsComponent as ReusableSponsorshipDetails } from '../../../../../reusable-components/sponsorship-details/sponsorship-details.component';
import { ConfirmDialog, ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { LoadingService } from '../../../../../services/loading.service';

@Component({
  selector: 'app-sponsorship-details-commercial',
  standalone: true,
  imports: [
    ReusableSponsorshipDetails,
    ConfirmDialogModule,
    ButtonModule
  ],
  templateUrl: './sponsorship-details.component.html',
  styleUrl: './sponsorship-details.component.sass'
})
export class SponsorshipDetailsComponent implements OnInit, OnDestroy {

  sponsorshipDetails: any = {};

  @ViewChild('confirmDialogRef') confirmDialogRef!: ConfirmDialog;

  processingStatus: any[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    private confirmationService: ConfirmationService,
    private loadingService: LoadingService,
    private commercialService: CommercialService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    const reference = this.route.snapshot.paramMap.get('reference')!;
    this.commercialService.getSponsorshipDetails(reference)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorshipDetails = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });

    this.commercialService.getProcessingStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.processingStatus = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  getLoading() {
    return this.loadingService.get();
  }

  addNote(event: any) {
    this.commercialService.addNoteToSponsorship(event.sponsorshipId, event.message)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorshipDetails.events = [...this.sponsorshipDetails.events, response];
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  confirmUpdateStatus(event: any) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      accept: () => {
        this.commercialService.updateSponsorshipStatus(event.sponsorshipId, event.statusId)
          .pipe(takeUntil(this._destroy$))
          .subscribe({
            next: response => {
              this.sponsorshipDetails = response;
              this.messageService.add({ severity: 'success', detail: 'Statut mis à jour', life: 8000 });
            }, error: error => {
              this.messageService.add({ severity: 'error', detail: error.description });
            }
          });
      },
      reject: () => { }
    });
  }

  rejectUpdateStatus() {
    this.confirmDialogRef.reject();
  }

  acceptUpdateStatus() {
    this.confirmDialogRef.accept();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}

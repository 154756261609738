import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SuperAdminService } from '../../../../services/super-admin.service';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../../../services/loading.service';
import { NewCompanyRequest } from '../../../../payloads/requests/newCompanyRequest';
import { TableModule, TableRowSelectEvent } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Router } from '@angular/router';
import { MessagesModule } from 'primeng/messages';

@Component({
  selector: 'app-companies',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    InputTextModule,
    DialogModule,
    ReactiveFormsModule,
    CheckboxModule,
    ScrollPanelModule,
    MessagesModule
  ],
  templateUrl: './companies.component.html',
  styleUrl: './companies.component.sass'
})
export class CompaniesComponent implements OnInit, OnDestroy {

  companies: any[] = [];

  newCompanyDialogIsVisible: boolean = false;

  newCompanyForm = this.formBuilder.group({
    name: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,50}$')])],
    siren: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{9}$')])],
    email: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    adminEmail: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    adminLastName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    adminFirstName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    adminPhone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    legalNoticesAccepted: [false, Validators.requiredTrue]
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private superAdminService: SuperAdminService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.superAdminService.getAllCompanies()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.companies = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      })
  }

  getLoading() {
    return this.loadingService.get();
  }

  onRowSelectCompany(event: TableRowSelectEvent) {
    const reference = event.data.reference;
    this.router.navigate([`portal/super-admin/company-details/${reference}`]);
  }

  showNewCompanyDialog() {
    this.newCompanyForm.reset();
    this.newCompanyDialogIsVisible = true;
  }

  onSubmitNewCompany() {
    const request: NewCompanyRequest = new NewCompanyRequest(
      this.newCompanyForm.get('name')?.value!,
      this.newCompanyForm.get('siren')?.value!,
      this.newCompanyForm.get('email')?.value!,
      this.newCompanyForm.get('phone')?.value!,
      this.newCompanyForm.get('adminEmail')?.value!,
      this.newCompanyForm.get('adminLastName')?.value!,
      this.newCompanyForm.get('adminFirstName')?.value!,
      this.newCompanyForm.get('adminPhone')?.value!);
    this.superAdminService.createCompany(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.router.navigate([`portal/super-admin/company-details/${response}`]);
        }, error: error => {
          error = JSON.parse(error);
          this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-company' });
        }
      })
  }

  closeNewCompanyDialog() {
    this.newCompanyDialogIsVisible = false;
  }

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}

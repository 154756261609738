<div class="block">
    <div>
        <div class="text-2xl font-medium text-900 mb-3 p-2">Données entreprise</div>

        <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">
            <form [formGroup]="updateCompanyDetailsForm">
                <div class="p-2 mb-5 text-sm text-500 font-medium">
                    Référence: {{ companyDetails.reference}}
                </div>

                <div class="flex flex-column p-2 mb-8">
                    <div class="flex font-bold mb-2">
                        LOGO
                    </div>
                    <div class="flex">
                        <div class="flex flex-column">
                            <div class="relative mx-auto">
                                <div class="flex">
                                    <p-fileUpload #fileUpload accept="image/png, image/jpeg" maxFileSize="150000"
                                        previewWidth="100" showCancelButton="false" customUpload="true"
                                        (uploadHandler)="uploadFile($event)">
                                        <ng-template pTemplate="header" let-files let-chooseCallback="chooseCallback"
                                            let-uploadCallback="uploadCallback">
                                            <p-button (onClick)="choose($event, chooseCallback)" icon="pi pi-images"
                                                [rounded]="true" [outlined]="true" />
                                            <p-button (onClick)="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                                [loading]="getLoading()" [rounded]="true" [outlined]="true"
                                                severity="success"
                                                [disabled]="!files || files.length === 0 || getLoading()" />
                                        </ng-template>
                                    </p-fileUpload>
                                    <div *ngIf="companyDetails.logoFilePath != null" class="ml-3">
                                        <p-image [src]="getLogoFullUrl(companyDetails.logoFilePath)" alt="Image"
                                            width="100" [preview]="true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2 gap-2">
                        <span>NOM</span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('name')" [hidden]="hideName">
                            <i class="pi pi-pencil" style="color: var(--primary-color)"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onSubmitUpdateCompany()" [hidden]="!hideName">
                            <i class="pi pi-save" style="color: green"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('name')" [hidden]="!hideName">
                            <i class="pi pi-undo" style="color: red"></i>
                        </span>
                    </div>
                    <div class="flex">
                        <span [hidden]="hideName">{{ companyDetails.name }}</span>

                        <div [hidden]="!hideName">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="companyDetails.name" pInputText formControlName="name"
                                    aria-describedby="name-edit">
                                <small class="text-red-600" id="name-edit"
                                    *ngIf="updateCompanyDetailsForm.controls.name.errors?.['pattern']">
                                    Règles: que des caractères alphanumériques, entre 2 et 50 caractères.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2">
                        SIREN
                    </div>
                    <div class="flex">
                        {{ companyDetails.siren }}
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2 gap-2">
                        <span>COORDONNÉES</span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('contactDetails')"
                            [hidden]="hideContactDetails">
                            <i class="pi pi-pencil" style="color: var(--primary-color)"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onSubmitUpdateCompany()" [hidden]="!hideContactDetails">
                            <i class="pi pi-save" style="color: green"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('contactDetails')"
                            [hidden]="!hideContactDetails">
                            <i class="pi pi-undo" style="color: red"></i>
                        </span>
                    </div>
                    <div class="flex mb-2">
                        <span [hidden]="hideContactDetails">{{ companyDetails.email }}</span>

                        <div [hidden]="!hideContactDetails">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="companyDetails.email" pInputText
                                    formControlName="email" aria-describedby="email-edit">
                                <small class="text-red-600" id="email-edit"
                                    *ngIf="updateCompanyDetailsForm.controls.email.errors?.['pattern']">
                                    Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <span [hidden]="hideContactDetails">{{ companyDetails.phone }}</span>

                        <div [hidden]="!hideContactDetails">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="companyDetails.phone" pInputText
                                    formControlName="phone" aria-describedby="phone-edit">
                                <small class="text-red-600" id="phone-edit"
                                    *ngIf="updateCompanyDetailsForm.controls.phone.errors?.['pattern']">
                                    Règles: 10 chiffres.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2 gap-2">
                        <span>ADRESSE</span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('addressDetails')"
                            [hidden]="hideAddressDetails">
                            <i class="pi pi-pencil" style="color: var(--primary-color)"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onSubmitUpdateCompany()" [hidden]="!hideAddressDetails">
                            <i class="pi pi-save" style="color: green"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('addressDetails')"
                            [hidden]="!hideAddressDetails">
                            <i class="pi pi-undo" style="color: red"></i>
                        </span>
                    </div>
                    <div class="flex mb-2">
                        <span [hidden]="hideAddressDetails">{{ companyDetails.address }}</span>

                        <div [hidden]="!hideAddressDetails">
                            <div class="flex flex-column">
                                <input type="text"
                                    [placeholder]="companyDetails.address ? companyDetails.address : 'Adresse'"
                                    pInputText formControlName="address" aria-describedby="address-edit">
                                <small class="text-red-600" id="address-edit"
                                    *ngIf="updateCompanyDetailsForm.controls.address.errors?.['pattern']">
                                    Règles: que des caractères alphanumériques, entre 2 et 50 caractères.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="flex mb-2">
                        <span [hidden]="hideAddressDetails">{{ companyDetails.postalCode }}</span>

                        <div [hidden]="!hideAddressDetails">
                            <div class="flex flex-column">
                                <input type="text"
                                    [placeholder]="companyDetails.postalCode ? companyDetails.postalCode : 'Code postal'"
                                    pInputText formControlName="postalCode" aria-describedby="postalCode-edit">
                                <small class="text-red-600" id="postalCode-edit"
                                    *ngIf="updateCompanyDetailsForm.controls.postalCode.errors?.['pattern']">
                                    Règles: 5 chiffres.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <span [hidden]="hideAddressDetails">{{ companyDetails.city }}</span>

                        <div [hidden]="!hideAddressDetails">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="companyDetails.city ? companyDetails.city : 'Ville'"
                                    pInputText formControlName="city" aria-describedby="city-edit">
                                <small class="text-red-600" id="city-edit"
                                    *ngIf="updateCompanyDetailsForm.controls.city.errors?.['pattern']">
                                    Règles: que des caractères alphanumériques, entre 2 et 30 caractères.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2 gap-2">
                        <span>MA BANQUE</span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('bank')" [hidden]="hideBank">
                            <i class="pi pi-pencil" style="color: var(--primary-color)"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onSubmitUpdateCompany()" [hidden]="!hideBank">
                            <i class="pi pi-save" style="color: green"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('bank')" [hidden]="!hideBank">
                            <i class="pi pi-undo" style="color: red"></i>
                        </span>
                    </div>
                    <div class="flex">
                        <div [hidden]="hideBank">
                            <div class="flex align-items-center gap-1 mb-1">
                                <span>{{ companyDetails.bridgeBank?.name }}</span>
                                <p-image [src]="companyDetails.bridgeBank?.logoUrl"
                                    [alt]="companyDetails.bridgeBank?.name" [preview]="true" width="50" />
                            </div>
                            <p-tag severity="warning" [value]="companyDetails.bridgeBank?.capability"
                                [pTooltip]="setCapabilityTagTooltipDesc(companyDetails.bridgeBank?.capability)"
                                *ngIf="companyDetails.bridgeBank?.capability" />
                        </div>

                        <div [hidden]="!hideBank">
                            <p-listbox [options]="bridgeBanks" formControlName="bank" optionLabel="name"
                                [filter]="true">
                                <ng-template let-bank pTemplate="item">
                                    <div class="flex align-items-center gap-2">
                                        <img [src]="bank.logo_url" style="width: 20px" />
                                        <div>{{ bank.name }}</div>
                                    </div>
                                </ng-template>
                            </p-listbox>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2 gap-2">
                        <span>MONTANT PARRAINAGE</span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('sponsorshipAmount')"
                            [hidden]="hideSponsorshipAmount">
                            <i class="pi pi-pencil" style="color: var(--primary-color)"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onSubmitUpdateCompany()"
                            [hidden]="!hideSponsorshipAmount">
                            <i class="pi pi-save" style="color: green"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('sponsorshipAmount')"
                            [hidden]="!hideSponsorshipAmount">
                            <i class="pi pi-undo" style="color: red"></i>
                        </span>
                    </div>
                    <div class="flex">
                        <span [hidden]="hideSponsorshipAmount">{{ companyDetails.sponsorshipAmount }},00 €</span>

                        <div [hidden]="!hideSponsorshipAmount">
                            <div class="flex flex-column">
                                <p-inputNumber formControlName="sponsorshipAmount"
                                    [placeholder]="companyDetails.sponsorshipAmount" mode="currency" currency="EUR"
                                    locale="fr-FR" aria-describedby="sponsorshipAmount-edit" />
                                <small class="text-red-600" id="sponsorshipAmount-edit"
                                    *ngIf="updateCompanyDetailsForm.controls.sponsorshipAmount.errors?.['pattern']">
                                    Règles: que des caractères alphanumériques, entre 2 et 50 caractères.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2 gap-2">
                        <span>DESCRIPTION</span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('description')"
                            [hidden]="hideDescription">
                            <i class="pi pi-pencil" style="color: var(--primary-color)"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onSubmitUpdateCompany()" [hidden]="!hideDescription">
                            <i class="pi pi-save" style="color: green"></i>
                        </span>
                        <span class="cursor-pointer" (click)="onClickEnableFieldInput('description')"
                            [hidden]="!hideDescription">
                            <i class="pi pi-undo" style="color: red"></i>
                        </span>
                    </div>
                    <div class="flex">
                        <span [hidden]="hideDescription">{{ companyDetails.description }}</span>

                        <div [hidden]="!hideDescription">
                            <div class="flex flex-column">
                                <textarea [placeholder]="companyDetails.description" pInputTextarea
                                    formControlName="description" aria-describedby="description-edit" maxlength="255"
                                    rows="5" cols="30"></textarea>
                                <small class="text-red-600" id="description-edit"
                                    *ngIf="updateCompanyDetailsForm.controls.description.errors?.['pattern']">
                                    Règles: entre 30 et 255 caractères.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="md:flex justify-content-between">
                    <div class="flex flex-column p-2 md:mb-0 mb-5">
                        <div class="flex font-bold mb-2">
                            PARRAINAGES LINKUPP
                        </div>
                        <div class="flex">
                            <span class="font-medium">
                                Tous:&nbsp;
                            </span>
                            {{ companyDetails.sponsorshipsNumber }}
                        </div>
                        <div class="flex">
                            <span class="font-medium">
                                En cours:&nbsp;
                            </span>
                            {{ companyDetails.runningSponsorshipsNumber }}
                        </div>
                        <div class="flex">
                            <span class="font-medium">
                                Validé(s):&nbsp;
                            </span>
                            {{ companyDetails.completedSponsorshipsNumber }}
                        </div>
                        <div class="flex">
                            <span class="font-medium">
                                Annulé(s):&nbsp;
                            </span>
                            {{ companyDetails.canceledSponsorshipsNumber }}
                        </div>
                    </div>
                    <div class="flex flex-column p-2 md:p-5">
                        <div class="flex font-bold mb-2">
                            LEADS LINKUPP
                        </div>
                        <div class="flex md:justify-content-end">
                            <span class="font-medium">
                                Tous:&nbsp;
                            </span>
                            {{ companyDetails.leadsNumber }}
                        </div>
                        <div class="flex md:justify-content-end">
                            <span class="font-medium">
                                En cours:&nbsp;
                            </span>
                            {{ companyDetails.runningLeadsNumber }}
                        </div>
                        <div class="flex md:justify-content-end">
                            <span class="font-medium">
                                Validé(s):&nbsp;
                            </span>
                            {{ companyDetails.completedLeadsNumber }}
                        </div>
                        <div class="flex md:justify-content-end">
                            <span class="font-medium">
                                Annulé(s):&nbsp;
                            </span>
                            {{ companyDetails.canceledLeadsNumber }}
                        </div>
                    </div>
                </div>
            </form>

            <p-accordion class="mt-5" *ngIf="companyDetails.events?.length > 0">
                <p-accordionTab header="Historique">
                    <p-timeline [value]="companyDetails.events" class="m-1">
                        <ng-template pTemplate="content" let-event>
                            <small>{{ event.createdBy }}, {{ event.createdAt | date:'medium' }}</small>
                            <p class="p-text-secondary">{{ event.eventMessage }}</p>
                        </ng-template>
                    </p-timeline>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
export class NewSponsorshipRequest {

  companyId: number | null;
  godChildFirstName: string | null;
  godChildLastName: string | null;
  godChildEmail: string | null;
  godChildPhone: string | null;
  description: string | null;
  city: string | null;

  constructor(companyId: number | null, godChildFirstName: string | null, godChildLastName: string | null, godChildEmail: string | null, godChildPhone: string | null, description: string | null, city: string | null) {
    this.companyId = companyId;
    this.godChildFirstName = godChildFirstName;
    this.godChildLastName = godChildLastName;
    this.godChildEmail = godChildEmail;
    this.godChildPhone = godChildPhone;
    this.description = description;
    this.city = city;
  }

}
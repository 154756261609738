import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateProfileRequest } from '../payloads/requests/updateProfileRequest';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API = `${environment.rootApiUrl}/api/user`;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  getProfile(): Observable<any> {
    return this.http.get(`${API}/my-profile`);
  }

  updateProfile(request: UpdateProfileRequest): Observable<any> {
    return this.http.put(`${API}/my-profile`, request);
  }

}

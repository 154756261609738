import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../../../services/customer.service';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from 'primeng/api';
import { SponsorshipDetailsComponent as ReusableSponsorshipDetails } from '../../../../reusable-components/sponsorship-details/sponsorship-details.component';

@Component({
  selector: 'app-sponsorship-details-customer',
  standalone: true,
  imports: [
    ReusableSponsorshipDetails
  ],
  templateUrl: './sponsorship-details.component.html',
  styleUrl: './sponsorship-details.component.sass'
})
export class SponsorshipDetailsComponent implements OnInit, OnDestroy {

  sponsorshipDetails: any = {};

  private _destroy$ = new Subject<void>();

  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    const reference = this.route.snapshot.paramMap.get('reference')!;
    this.customerService.getSponsorshipDetails(reference)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorshipDetails = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  addNote(event: any) {
    this.customerService.addNoteToSponsorship(event.sponsorshipId, event.message)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorshipDetails.events = [...this.sponsorshipDetails.events, response];
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}

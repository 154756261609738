export class UpdateProfileRequest {

  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  currentPassword: string | null;
  newPassword: string | null;
  iban: string | null;

  constructor(firstName: string | null, lastName: string | null, email: string | null, phone: string | null, currentPassword: string | null, newPassword: string | null, iban: string | null) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
    this.iban = iban;
  }
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommercialService } from '../../../../../services/commercial.service';
import { Subject, takeUntil } from 'rxjs';
import { LeadsComponent as ReusableLeads } from '../../../../../reusable-components/leads/leads.component';

@Component({
  selector: 'app-leads-commercial',
  standalone: true,
  imports: [
    ReusableLeads
  ],
  templateUrl: './leads.component.html',
  styleUrl: './leads.component.sass'
})
export class LeadsComponent implements OnInit, OnDestroy {

  leads: any[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    private messageService: MessageService,
    private commercialService: CommercialService
  ) { }

  ngOnInit(): void {
    this.commercialService.getAllLeads()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.leads = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '../../../../../services/admin.service';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { SponsorshipsComponent as ReusableSponsorships } from '../../../../../reusable-components/sponsorships/sponsorships.component';

@Component({
  selector: 'app-sponsorships-admin',
  standalone: true,
  imports: [
    ReusableSponsorships
  ],
  templateUrl: './sponsorships.component.html',
  styleUrl: './sponsorships.component.sass'
})
export class SponsorshipsComponent implements OnInit, OnDestroy {

  sponsorships: any[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    private adminService: AdminService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.adminService.getAllSponsorships()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorships = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}

import { BridgeBankRequest } from "./bridgeBankRequest";

export class UpdateCompanyDetailsRequest {

  name: string | null;
  email: string | null;
  phone: string | null;
  description: string | null;
  sponsorshipAmount: number | null;
  bridgeBankRequest: BridgeBankRequest | null;
  address: string | null;
  postalCode: string |null;
  city: string | null;

  constructor(name: string | null, email: string | null, phone: string | null, description: string | null, sponsorshipAmount: number | null, bridgeBankRequest: BridgeBankRequest | null, address: string | null, postalCode: string |null, city: string | null) {
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.description = description;
    this.sponsorshipAmount = sponsorshipAmount;
    this.bridgeBankRequest = bridgeBankRequest;
    this.address = address;
    this.postalCode = postalCode;
    this.city = city;
  }
}
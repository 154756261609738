export class BridgeBankRequest {

  id: number | null;
  name: string | null;
  logoUrl: string | null;
  capability: string | null;

  constructor(id: number | null, name: string | null, logoUrl: string | null, capability: string | null) {
    this.id = id;
    this.name = name;
    this.logoUrl = logoUrl;
    this.capability = capability;
  }
}
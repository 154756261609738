import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { UpdateCompanyDetailsRequest } from '../../../../../payloads/requests/updateCompanyDetailsRequest';
import { AdminService } from '../../../../../services/admin.service';
import { LoadingService } from '../../../../../services/loading.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule, IMAGE_CONFIG } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';
import { FileUpload, FileUploadHandlerEvent, FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AccordionModule } from 'primeng/accordion';
import { TimelineModule } from 'primeng/timeline';
import { InputNumberModule } from 'primeng/inputnumber';
import { ListboxModule } from 'primeng/listbox';
import { BridgeBankRequest } from '../../../../../payloads/requests/bridgeBankRequest';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-company-details',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    FileUploadModule,
    ImageModule,
    AccordionModule,
    TimelineModule,
    InputNumberModule,
    ListboxModule,
    TagModule,
    TooltipModule
  ],
  providers: [
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true
      }
    }
  ],
  templateUrl: './company-details.component.html',
  styleUrl: './company-details.component.sass'
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {

  companyDetails: any = {};

  bridgeBanks: any[] = [];

  s3ImageUrl: string = environment.s3ImageUrl;

  updateCompanyDetailsForm = this.formBuilder.group({
    name: [null, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,50}$')],
    email: [null, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')],
    phone: [null, Validators.pattern('^[0-9]{10}$')],
    description: [null, Validators.pattern('^.{30,255}$')],
    sponsorshipAmount: [null],
    bank: [null],
    address: [null, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,50}$')],
    postalCode: [null, Validators.pattern('^[0-9]{5}$')],
    city: [null, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,30}$')]
  }, { validators: [this.requireAtLeastOneFilledValidator()] })

  hideName: boolean = false;
  hideContactDetails: boolean = false;
  hideDescription: boolean = false;
  hideSponsorshipAmount: boolean = false;
  hideBank: boolean = false;
  hideAddressDetails: boolean = false;

  @ViewChild('fileUpload') fileUpload!: FileUpload;

  private _destroy$ = new Subject<void>();

  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.adminService.getCompanyDetails()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.companyDetails = response;
          this.companyDetails.events.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

          if (!response.bridgeBank) {
            this.messageService.add({ severity: 'warn', detail: 'Aucune banque n\'est configurée pour votre entreprise. Sans ça vous ne pourrez pas faire de paiements.' });
          }

          if (response.sponsorshipAmount === 0) {
            this.messageService.add({ severity: 'warn', detail: 'Le montant de récompense de vos parrainages est de 0€. Sans montant supérieur vous ne pourrez pas faire de paiements.' });
          }
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  getLoading() {
    return this.loadingService.get();
  }

  setCapabilityTagTooltipDesc(capability: string): string {
    switch (capability) {
      case 'single_payment':
        return "Cette banque n'accepte que les paiements uniques";
      case 'bulk_payment':
        return "Cette banque accepte les paiements uniques et multiples";
      default:
        return "";
    }
  }

  onClickEnableFieldInput(field: string) {
    switch (field) {
      case 'name': {
        this.hideName = !this.hideName;
        this.updateCompanyDetailsForm.patchValue({ name: null });
        break;
      }
      case 'contactDetails': {
        this.hideContactDetails = !this.hideContactDetails;
        this.updateCompanyDetailsForm.patchValue({ email: null });
        this.updateCompanyDetailsForm.patchValue({ phone: null });
        break;
      }
      case 'description': {
        this.hideDescription = !this.hideDescription;
        this.updateCompanyDetailsForm.patchValue({ description: null });
        break;
      }
      case 'sponsorshipAmount': {
        this.hideSponsorshipAmount = !this.hideSponsorshipAmount;
        this.updateCompanyDetailsForm.patchValue({ sponsorshipAmount: null });
        break;
      }
      case 'bank': {
        this.hideBank = !this.hideBank;
        if (this.hideBank) {
          if (!sessionStorage.getItem("linkupp_bridge_banks")) {
            this.adminService.getBridgeBanks()
              .pipe(takeUntil(this._destroy$))
              .subscribe({
                next: response => {
                  this.bridgeBanks = response.resources;
                  sessionStorage.setItem("linkupp_bridge_banks", JSON.stringify(response.resources));
                }, error: error => {
                  this.messageService.add({ severity: 'error', detail: 'Une erreur est survenue lors de la récupération des banques' });
                }
              });
          } else {
            this.bridgeBanks = JSON.parse(sessionStorage.getItem("linkupp_bridge_banks") || "[]");
          }
        }
        this.updateCompanyDetailsForm.patchValue({ bank: null });
        break;
      }
      case 'addressDetails': {
        this.hideAddressDetails = !this.hideAddressDetails;
        this.updateCompanyDetailsForm.patchValue({ address: null });
        this.updateCompanyDetailsForm.patchValue({ postalCode: null });
        this.updateCompanyDetailsForm.patchValue({ city: null });
        break;
      }
    }
  }

  requireAtLeastOneFilledValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasFilledControls = Object.values(control.value).some(
        (value: any) => value !== null && value !== undefined && value !== ''
      );

      return hasFilledControls ? null : { requireAtLeastOneFilled: true };
    };
  }

  onSubmitUpdateCompany() {
    const bank: any = this.updateCompanyDetailsForm.get('bank')?.value!;
    var bridgeBankRequest = null;
    if (bank) {
      if (bank.capabilities.includes("bulk_payment")) {
        bridgeBankRequest = new BridgeBankRequest(bank.id, bank.name, bank.logo_url, "bulk_payment");
      } else {
        bridgeBankRequest = new BridgeBankRequest(bank.id, bank.name, bank.logo_url, "single_payment");
      }
    }

    const request: UpdateCompanyDetailsRequest = new UpdateCompanyDetailsRequest(
      this.updateCompanyDetailsForm.get('name')?.value!,
      this.updateCompanyDetailsForm.get('email')?.value!,
      this.updateCompanyDetailsForm.get('phone')?.value!,
      this.updateCompanyDetailsForm.get('description')?.value!,
      this.updateCompanyDetailsForm.get('sponsorshipAmount')?.value!,
      bridgeBankRequest,
      this.updateCompanyDetailsForm.get('address')?.value!,
      this.updateCompanyDetailsForm.get('postalCode')?.value!,
      this.updateCompanyDetailsForm.get('city')?.value!
    );
    this.adminService.updateCompanyDetails(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.companyDetails = response;
          this.companyDetails.events.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

          this.hideName = false;
          this.hideContactDetails = false;
          this.hideDescription = false;
          this.hideSponsorshipAmount = false;
          this.hideBank = false;
          this.hideAddressDetails = false;

          this.updateCompanyDetailsForm.reset();

          this.messageService.add({ severity: 'success', detail: 'Données d\'entreprise mises à jour', life: 8000 });
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  choose(event: any, callback: any) {
    callback();
  }

  uploadEvent(callback: any) {
    callback();
  }

  uploadFile(event: FileUploadHandlerEvent) {
    var file: File = event.files[0];
    let formdata: FormData = new FormData();
    formdata.append('file', file, file.name);
    this.adminService.uploadLogo(formdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.companyDetails = response;
          this.fileUpload.clear();
          this.messageService.add({ severity: 'success', detail: 'Logo téléchargé et mis à jour', life: 8000 });
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  getLogoFullUrl(logoFilePath: string): string {
    return this.s3ImageUrl + '/' + logoFilePath;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, TableRowSelectEvent } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leads',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    InputTextModule,
    TableModule,
    ButtonModule
  ],
  templateUrl: './leads.component.html',
  styleUrl: './leads.component.sass'
})
export class LeadsComponent {

  @Input() leads: any[] = [];
  @Input() activeUser: string = "";

  @Output() newLeadEmitter = new EventEmitter<any>();

  constructor(
    private router: Router
  ) { }

  onRowSelectLead(event: TableRowSelectEvent) {
    const reference = event.data.reference;
    if (this.activeUser === 'admin') {
      this.router.navigate([`portal/pro/admin/lead-details/${reference}`]);
    } else if (this.activeUser === 'commercial') {
      this.router.navigate([`portal/pro/commercial/lead-details/${reference}`]);
    }
  }

  getTagBackgroundColor(status: string): string {
    switch (status) {
      case 'STATUS_COMPLETED':
        return "#689f38";
      case 'STATUS_CANCELED':
        return "#d32f2f";
      default:
        return "#fbc02d";
    }
  }

  showNewLeadDialog() {
    this.newLeadEmitter.emit();
  }

}

export class ContactFormRequest {

  name: string | null;
  email: string | null;
  object: string | null;
  message: string | null;

  constructor(name: string | null, email: string | null, object: string | null, message: string | null) {
    this.name = name;
    this.email = email;
    this.object = object;
    this.message = message;
  }

}
{
  "name": "linkupp",
  "version": "0.2.1",
  "scripts": {
    "ng": "ng",
    "start": "node server.js",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "build:development": "ng build --configuration development",
    "build:staging": "ng build --configuration staging",
    "build:production": "ng build --configuration production"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.6",
    "@angular/cli": "^18.0.7",
    "@angular/common": "^18.0.6",
    "@angular/compiler": "^18.0.6",
    "@angular/compiler-cli": "^18.0.6",
    "@angular/core": "^18.0.6",
    "@angular/forms": "^18.0.6",
    "@angular/platform-browser": "^18.0.6",
    "@angular/platform-browser-dynamic": "^18.0.6",
    "@angular/router": "^18.0.6",
    "chart.js": "^4.4.2",
    "express": "^4.19.2",
    "jwt-decode": "^4.0.0",
    "path": "^0.12.7",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.5",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "typescript": "~5.4.5",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.7",
    "@angular/cli": "^18.0.7",
    "@angular/compiler-cli": "^18.0.6",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.5"
  },
  "engines": {
    "node": "22.4.1",
    "npm": "10.8.2"
  }
}

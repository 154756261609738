import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { PublicService } from '../../services/public.service';
import { CommonModule, DOCUMENT, IMAGE_CONFIG } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../services/loading.service';
import { ContactFormRequest } from '../../payloads/requests/contactFormRequest';
import { Subject, takeUntil } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { StyleClassModule } from 'primeng/styleclass';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AvatarModule } from 'primeng/avatar';
import { ToastModule } from 'primeng/toast';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    ButtonModule,
    CarouselModule,
    ScrollTopModule,
    HeaderComponent,
    FooterComponent,
    ReactiveFormsModule,
    InputTextModule,
    CommonModule,
    StyleClassModule,
    FormsModule,
    InputTextareaModule,
    AvatarModule,
    ToastModule
  ],
  providers: [
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true
      }
    }
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.sass'
})
export class HomeComponent implements OnInit, OnDestroy {

  partners: any[] = [];

  responsiveOptions: any[] | undefined;

  s3ImageUrl: string = environment.s3ImageUrl;

  contactForm = this.formBuilder.group({
    name: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,40}$')])],
    email: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    object: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,50}$')])],
    message: ['', Validators.compose([Validators.required, Validators.pattern('^.{15,255}$')])]
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private publicService: PublicService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: '1400px',
        numVisible: 4,
        numScroll: 1
      },
      {
        breakpoint: '1200px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '1000px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '800px',
        numVisible: 1,
        numScroll: 1
      }
    ];

    if (!localStorage.getItem("linkupp_partners")) {
      this.publicService.getAll()
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: response => {
            this.partners = response;
            localStorage.setItem("linkupp_partners", JSON.stringify(response));
          }, error: error => {
            if (error.description) {
              this.messageService.add({ severity: 'error', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
            } else {
              this.messageService.add({ severity: 'error', detail: 'Une erreur interne est survenue lors de la récupération des partenaires', icon: 'pi pi-times-circle', life: 5000 });
            }
          }
        })
    } else {
      this.partners = JSON.parse(localStorage.getItem("linkupp_partners") || "[]");
    }
  }

  getLoading() {
    return this.loadingService.get();
  }

  getLogoFullUrl(logoFilePath: string): string {
    return this.s3ImageUrl + '/' + logoFilePath;
  }

  discardContactForm() {
    this.contactForm.reset();
  }

  onSubmitContactForm() {
    const request: ContactFormRequest = new ContactFormRequest(
      this.contactForm.get('name')?.value!,
      this.contactForm.get('email')?.value!,
      this.contactForm.get('object')?.value!,
      this.contactForm.get('message')?.value!);
    this.publicService.contactService(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.contactForm.reset();
          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Votre formulaire de contact a été envoyé au service LinkUpp' });
        },
        error: error => {
          this.messageService.add({ severity: 'error', summary: 'Envoie du formulaire', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      })
  }

  scrollIntoView(elem: string) {
    this.document.querySelector(elem)!.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { TimelineModule } from 'primeng/timeline';
import { LoadingService } from '../../services/loading.service';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sponsorship-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TimelineModule,
    DropdownModule,
    ButtonModule,
    InputTextareaModule
  ],
  templateUrl: './sponsorship-details.component.html',
  styleUrl: './sponsorship-details.component.sass'
})
export class SponsorshipDetailsComponent {

  s3ImageUrl: string = environment.s3ImageUrl;

  @Input() sponsorshipDetails: any = {};
  @Input() processingStatus: any[] = [];
  @Input() activeUser: string = "";

  @Output() addNoteEmitter = new EventEmitter<any>();
  @Output() changeStatusEmitter = new EventEmitter<any>();

  sponsorshipMessageEvent: string = "";

  constructor(
    private loadingService: LoadingService
  ) { }

  getLoading() {
    return this.loadingService.get();
  }

  onClickAddNote() {
    this.addNoteEmitter.emit({ sponsorshipId: this.sponsorshipDetails.id, message: this.sponsorshipMessageEvent });
    this.sponsorshipMessageEvent = "";
  }

  onChangeStatus(event: DropdownChangeEvent) {
    this.changeStatusEmitter.emit({ sponsorshipId: this.sponsorshipDetails.id, statusId: event.value.id });
  }

  getLogoFullUrl(logoFilePath: string): string {
    return this.s3ImageUrl + '/' + logoFilePath;
  }

}

export class NewEmployeeRequest {

  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  isAdmin: boolean;
  isCommercial: boolean;

  constructor(firstName: string | null, lastName: string | null, email: string | null, phone: string | null, isAdmin: boolean, isCommercial: boolean) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.isAdmin = isAdmin;
    this.isCommercial = isCommercial;
  }

}
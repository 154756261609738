import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '../../../../../services/admin.service';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-payment-details',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    TooltipModule,
    TagModule,
    RouterModule
  ],
  providers: [DatePipe],
  templateUrl: './payment-details.component.html',
  styleUrl: './payment-details.component.sass'
})
export class PaymentDetailsComponent implements OnInit, OnDestroy {

  paymentDetails: any = {};

  statusReasonTagTooltipDesc: string = "";

  private _destroy$ = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    const paymentId = this.route.snapshot.paramMap.get('id')!;
    this.adminService.getPaymentRequestDetails(paymentId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          if (Object.keys(response.sender).length === 0 && response.status != "RJCT") {
            const creationDate = new Date(response.created_at);
            creationDate.setMinutes(creationDate.getMinutes() + 15);

            this.messageService.add({ severity: 'warn', detail: `Il semblerait que vous n'êtes pas aller au bout du paiement. Pour finaliser le paiement merci de vous rendre ici: https://pay.bridgeapi.io/payment/${paymentId}/initiate. Pour rappel le lien est valable 15 min après la création du paiement soit jusqu'au ${this.datePipe.transform(creationDate, 'medium')}.` });
          }
          this.paymentDetails = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: `Un erreur est survenue lors de la récupération des détails du paiement ${paymentId}` })
        }
      });
  }

  getTagBackgroundColor(status: string): string {
    switch (status) {
      case 'CREA':
        return "#2196F3";
      case 'ACTC':
        return "#2196F3";
      case 'PDNG':
        return "#fbc02d";
      case 'ACSC':
        return "#689f38";
      case 'RJCT':
        return "#d32f2f";
      default:
        return "#2196F3";
    }
  }

  setStatusTagTooltipDesc(status: string): string {
    switch (status) {
      case 'CREA':
        return "Le paiement a été créé côté Bridge";
      case 'ACTC':
        return "Le paiement a été créé côté banque";
      case 'PDNG':
        return "Le paiement a été confirmé et est en attente d'exécution par la banque";
      case 'ACSC':
        return "Le paiement a été exécuté par la banque";
      case 'RJCT':
        return "Le paiement a été rejeté";
      default:
        return "";
    }
  }

  setStatusReasonTagTooltipDesc(statusReason: string): string {
    switch (statusReason) {
      case 'AC01':
        return "Le numéro de compte est invalide ou n'existe pas";
      case 'AC04':
        return "Le compte est fermé et ne peut pas être utilisé";
      case 'AC06':
        return "Le compte est bloqué et ne peut pas être utilisé";
      case 'AG01':
        return "Transaction interdite sur ce type de compte";
      case 'AM18':
        return "Le nombre de transactions dépasse la limite d'acceptation ASPSP";
      case 'CH03':
        return "La date d'exécution demandée est trop éloignée dans le futur";
      case 'CUST':
        return "Le rejet est dû au débiteur : refus ou manque de liquidité";
      case 'DS02':
        return "Un utilisateur autorisé a annulé la commande";
      case 'FF01':
        return "Le rejet est dû à la demande de paiement d'origine qui n'est pas valide (syntaxe, structure ou valeurs)";
      case 'FRAD':
        return "La demande de paiement est considérée comme frauduleuse";
      case 'MS03':
        return "Aucune raison précisée par l'ASPSP (généralement la banque)";
      case 'NOAS':
        return "Le PSU (l'utilisateur) n'a ni accepté ni rejeté la demande de paiement et un délai d'attente s'est produit";
      case 'RR01':
        return "Le compte du débiteur et/ou l'identification sont manquants ou incohérents";
      case 'RR03':
        return "La spécification du nom et/ou de l'adresse du créancier nécessaire aux exigences réglementaires est insuffisante ou manquante";
      case 'RR04':
        return "Rejet pour des raisons réglementaires";
      case 'RR12':
        return "Identification non valide ou manquante requise dans un pays ou un type de paiement particulier";
      default:
        return "";
    }
  }

  getAccordionTabHeader(id: string): string {
    return `Bridge transaction ID ${id}`;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CheckCustomerRequest } from '../payloads/requests/checkCustomerRequest';
import { NewCustomerRequest } from '../payloads/requests/newCustomerRequest';

const API = `${environment.rootApiUrl}/api/commercial`;

@Injectable({
  providedIn: 'root'
})
export class CommercialService {

  constructor(
    private http: HttpClient
  ) { }

  getAllSponsorships(): Observable<any> {
    return this.http.get(`${API}/sponsorships`);
  }

  getSponsorshipDetails(reference: string): Observable<any> {
    return this.http.get(`${API}/sponsorships/details`,
      {
        params: {
          reference: reference
        }
      }
    );
  }

  updateSponsorshipStatus(sponsorshipId: number, statusId: number): Observable<any> {
    return this.http.put(`${API}/sponsorships/update-status`, {},
      {
        params: {
          sponsorshipId: sponsorshipId,
          statusId: statusId
        }
      }
    );
  }

  addNoteToSponsorship(sponsorshipId: number, message: string): Observable<any> {
    return this.http.post(`${API}/sponsorships/add-note`, message,
      {
        params: {
          sponsorshipId: sponsorshipId
        }
      }
    );
  }

  getProcessingStatus(): Observable<any> {
    return this.http.get(`${API}/processing-status`);
  }

  getAllProspects(): Observable<any> {
    return this.http.get(`${API}/prospects`);
  }

  getAllCustomers(): Observable<any> {
    return this.http.get(`${API}/customers`);
  }

  addCustomer(request: NewCustomerRequest): Observable<any> {
    return this.http.post(`${API}/customers`, request);
  }

  checkCustomer(request: CheckCustomerRequest, sponsorshipCreationMode: boolean): Observable<any> {
    return this.http.post(`${API}/customers/check-customer`, request,
      {
        params: {
          sponsorshipCreationMode: sponsorshipCreationMode
        }
      }
    );
  }

  getAllLeads(): Observable<any> {
    return this.http.get(`${API}/leads`);
  }

  addNoteToLead(leadId: number, message: string): Observable<any> {
    return this.http.post(`${API}/leads/add-note`, message,
      {
        params: {
          leadId: leadId
        }
      }
    );
  }

  updateLeadStatus(leadId: number, statusId: number): Observable<any> {
    return this.http.put(`${API}/leads/update-status`, {},
      {
        params: {
          leadId: leadId,
          statusId: statusId
        }
      }
    );
  }

  getLeadDetails(reference: string): Observable<any> {
    return this.http.get(`${API}/leads/details`,
      {
        params: {
          reference: reference
        }
      }
    );
  }

}

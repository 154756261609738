import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { JwtService } from './services/jwt.service';

export const authCanActivateGuard: CanActivateFn = (route, state) => {
  const _router = inject(Router);
  const _jwtService = inject(JwtService);

  const { url } = state;

  if (url?.includes('login')) {
    if(!_jwtService.isValidAuthentication()) { return true; }

    _router.navigate(['portal']);
    return false;
  }

  if(_jwtService.isTokenExpired()) {
    _jwtService.clear();
    _router.navigate(['login'], { queryParams: { error: "The JWT token has expired" }});
    return false;
  }

  // portal
  if (url?.includes('portal') && _jwtService.isValidAuthentication()) {

    // my-profile
    if (url?.includes('my-profile') && _jwtService.isUser) { return true; }

    // super admin
    if (url?.includes('super-admin') && _jwtService.isSuperAdmin) { return true; }

    // customer
    if (url?.includes('customer') && _jwtService.isCustomer) { return true; }

    // pro
    if (url?.includes('pro') && (_jwtService.isAdmin || _jwtService.isCommercial)) {
      // admin
      if (url?.includes('admin') && _jwtService.isAdmin) { return true; }

      // commercial
      if (url?.includes('commercial') && _jwtService.isCommercial) { return true; }

      return true;
    }
  } else {
    _jwtService.clear();
    _router.navigate(['login']);
    return false;
  }

  _router.navigate(['forbidden']);
  return false;

};

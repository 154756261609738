<div class="block">
    <div>
        <div class="text-2xl font-medium text-900 mb-3 p-2">Mon profile</div>

        <div class="flex flex-column p-4 surface-card shadow-2 border-round">
            <div class="p-2 mb-5 text-sm text-500 font-medium">
                Référence: {{ myProfile.reference}}
            </div>
            
            <form [formGroup]="updateMyProfileForm" (ngSubmit)="onSubmitUpdatePrincipalProfile()">
                <ul class="list-none p-0 m-0">
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Nom d'utilisateur</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ myProfile.username }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Nom</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hideLastName">{{
                            myProfile.lastName }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hideLastName">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myProfile.lastName" pInputText
                                    formControlName="lastName" aria-describedby="lastName-edit">
                                <small class="text-red-600" id="lastName-edit"
                                    *ngIf="updateMyProfileForm.controls.lastName.errors?.['pattern']">
                                    Règles: que des lettres, entre 2 et 30 caractères.
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                (onClick)="onClickEnableFieldInput('lastName')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Prénom</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hideFirstName">{{
                            myProfile.firstName }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hideFirstName">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myProfile.firstName" pInputText
                                    formControlName="firstName" aria-describedby="firstName-edit">
                                <small class="text-red-600" id="firstName-edit"
                                    *ngIf="updateMyProfileForm.controls.firstName.errors?.['pattern']">
                                    Règles: que des lettres, entre 2 et 30 caractères.    
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                (onClick)="onClickEnableFieldInput('firstName')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Email</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hideEmail">{{
                            myProfile.email }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hideEmail">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myProfile.email" pInputText formControlName="email"
                                    aria-describedby="email-edit">
                                <small class="text-red-600" id="email-edit"
                                    *ngIf="updateMyProfileForm.controls.email.errors?.['pattern']">
                                    Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                (onClick)="onClickEnableFieldInput('email')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Téléphone</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hidePhone">{{
                            myProfile.phone }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hidePhone">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myProfile.phone" pInputText formControlName="phone"
                                    aria-describedby="phone-edit">
                                <small class="text-red-600" id="phone-edit"
                                    *ngIf="updateMyProfileForm.controls.phone.errors?.['pattern']">
                                    Règles: 10 chiffres.
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                (onClick)="onClickEnableFieldInput('phone')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Mot de passe</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hidePassword">*****
                        </div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hidePassword">
                            <div class="flex flex-column">
                                <div class="flex mt-3">
                                    <p-floatLabel>
                                        <p-password formControlName="currentPassword" [feedback]="false"
                                            [toggleMask]="true" />
                                        <label>Mot de passe actuel</label>
                                    </p-floatLabel>
                                </div>
                                <div class="flex mt-4">
                                    <div class="flex flex-column">
                                        <p-floatLabel>
                                            <p-password [toggleMask]="true" [strongRegex]="passwordRegex"
                                                formControlName="newPassword" promptLabel="Choisir un mot de passe">
                                                <ng-template pTemplate="footer">
                                                    <p-divider></p-divider>
                                                    <p class="mt-2">Règles</p>
                                                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                                        <li
                                                            [ngClass]="pwdLowerCase() ? 'text-green-600' : 'text-red-900'">
                                                            Au moins 1 minuscule</li>
                                                        <li
                                                            [ngClass]="pwdUpperCase() ? 'text-green-600' : 'text-red-900'">
                                                            au moins 1 majuscule</li>
                                                        <li [ngClass]="pwdDigit() ? 'text-green-600' : 'text-red-900'">
                                                            Au
                                                            moins 1 chiffre</li>
                                                        <li
                                                            [ngClass]="pwdSpecialChar() ? 'text-green-600' : 'text-red-900'">
                                                            Au moins 1 caractère spécial: !&#64;#$%^&*()_+</li>
                                                        <li [ngClass]="pwdLength() ? 'text-green-600' : 'text-red-900'">
                                                            Entre 12 et 20 caractères</li>
                                                    </ul>
                                                </ng-template>
                                            </p-password>
                                            <label>Nouveau mot de passe</label>
                                        </p-floatLabel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                (onClick)="onClickEnableFieldInput('password')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap" *ngIf="isCustomer">
                        <div class="flex text-500 w-6 md:w-2 font-medium gap-2">
                            <span>
                                <i class="pi pi-info-circle" style="font-size: 0.8rem"
                                    pTooltip="Votre IBAN sera partagé avec notre partenaire Bridge pour que vos entreprises partenaires puissent récompenser vos parrainages"></i>
                            </span>
                            <span>IBAN</span>
                            <span *ngIf="!myProfile.iban">
                                <i class="pi pi-exclamation-triangle" style="color: var(--red-600); font-size: 0.8rem"
                                    pTooltip="Si vous ne renseignez pas votre IBAN, vous ne pourrez pas percevoir vos récompenses via notre plateforme"></i>
                            </span>
                        </div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hideIban">{{
                            myProfile.iban }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hideIban">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myProfile.iban ? myProfile.iban : 'IBAN'" pInputText formControlName="iban"
                                    aria-describedby="iban-edit">
                                <small class="text-red-600" id="iban-edit"
                                    *ngIf="updateMyProfileForm.controls.iban.errors?.['pattern']">
                                    Règles: 27 caractères au format IBAN français.
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                (onClick)="onClickEnableFieldInput('iban')" />
                        </div>
                    </li>
                    <div class="w-full flex justify-content-end mt-3">
                        <p-button icon="pi pi-save" [loading]="getLoading()"
                            label="Mettre à jour" [rounded]="true" [outlined]="true" type="submit"
                            [disabled]="!updateMyProfileForm.valid || getLoading()" />
                    </div>
                </ul>
            </form>

            <p-accordion class="mt-5" *ngIf="myProfile.events?.length > 0">
                <p-accordionTab header="Historique">
                    <p-timeline [value]="myProfile.events" class="m-1">
                        <ng-template pTemplate="content" let-event>
                            <small>{{ event.createdBy }}, {{ event.createdAt | date:'medium' }}</small>
                            <p class="p-text-secondary">{{ event.eventMessage }}</p>
                        </ng-template>
                    </p-timeline>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
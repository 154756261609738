<app-header />

<p-toast></p-toast>

<div class="block home-section-1"
    style="background: rgb(2,0,36); background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 61%, rgba(7,7,187,1) 100%);">
    <div class="flex md:hidden justify-content-between px-3 py-2">
        <p-button [rounded]="true" [outlined]="true" label="Avantages" (onClick)="scrollIntoView('#advantages')" />
        <p-button [rounded]="true" [outlined]="true" label="Partenaires" (onClick)="scrollIntoView('#partners')" />
        <p-button [rounded]="true" [outlined]="true" label="Contacts" (onClick)="scrollIntoView('#contacts')" />
    </div>
    <div class="grid grid-nogutter text-800">
        <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center">
            <section>
                <span class="block text-6xl font-bold mb-1 text-white">LinkUpp.</span>
                <div class="text-6xl text-white font-bold mb-3">Leader du parrainage</div>
                <p class="mt-0 mb-4 line-height-3 text-white">Si vous créez une expérience client, les clients
                    en
                    parleront. Le bouche à oreille est un outil très puissant.</p>
            </section>
        </div>
        <div class="col-12 lg:col-6 overflow-hidden">
            <img src="assets/images/web/mockup_2.png" alt="Image" class="md:ml-auto block md:h-full md:w-10 w-12"
                style="clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%)">
        </div>
    </div>
</div>

<div id="advantages" class="block surface-100 home-section-2">
    <div class="px-4 py-8 md:px-6 lg:px-8 text-center">
        <div class="mb-3 font-bold text-3xl mb-6">
            <span class="text-900">Un Outil,&nbsp;</span>
            <span class="text-blue-600">Plusieurs Avantages</span>
        </div>
        <div class="grid">
            <div class="col-12 md:col-4 mb-4 px-5">
                <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                    <i class="pi pi-users text-4xl text-blue-500"></i>
                </span>
                <div class="text-900 text-xl mb-3 font-medium">GENERER DE NOUVEAUX CLIENTS</div>
                <span class="text-700 line-height-3">Générer de nouveaux clients grâce à notre programme de
                    parrainage.</span>
            </div>
            <div class="col-12 md:col-4 mb-4 px-5">
                <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                    <i class="pi pi-chart-line text-4xl text-blue-500"></i>
                </span>
                <div class="text-900 text-xl mb-3 font-medium">BOOSTEZ VOS VENTES</div>
                <span class="text-700 line-height-3">Accélérez votre succès avec des solutions sur mesure pour votre entreprise.</span>
            </div>
            <div class="col-12 md:col-4 mb-4 px-5">
                <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                    <i class="pi pi-heart text-4xl text-blue-500"></i>
                </span>
                <div class="text-900 text-xl mb-3 font-medium">FIDELISER VOS CLIENTS</div>
                <span class="text-700 line-height-3">Fidélisez vos clients en les récompensant.</span>
            </div>
            <div class="col-12 md:col-4 mb-4 px-5">
                <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                    <i class="pi pi-sitemap text-4xl text-blue-500"></i>
                </span>
                <div class="text-900 text-xl mb-3 font-medium">PROGRAMME DE PARRAINAGE</div>
                <span class="text-700 line-height-3">Transformez la satisfaction client en recommandation client via
                    notre plateforme LinkUpp. Solution clé en main et 100% personnalisable.</span>
            </div>
            <div class="col-12 md:col-4 mb-4 px-5">
                <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                    <i class="pi pi-wallet text-4xl text-blue-500"></i>
                </span>
                <div class="text-900 text-xl mb-3 font-medium">SIMPLE ET EFFICACE</div>
                <span class="text-700 line-height-3">Plus abordable que les sponsorships et avec une meilleure qualité.</span>
            </div>
            <div class="col-12 md:col-4 md:mb-4 mb-0 px-3">
                <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                    <i class="pi pi-gift text-4xl text-blue-500"></i>
                </span>
                <div class="text-900 text-xl mb-3 font-medium">RECOMPENSEZ VOS CLIENTS</div>
                <span class="text-700 line-height-3">Notre service vous permet de récompenser vos clients sans frais et
                    sans perte de temps via notre plateforme de paiement.</span>
            </div>
        </div>
    </div>
</div>

<div id="partners" class="block home-section-3 border-bottom-2 border-100"
    style="background: rgb(2,0,36); background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 61%, rgba(7,7,187,1) 100%);">
    <div class="px-4 py-8 md:px-6 lg:px-8 text-center">
        <div class="text-white font-bold text-6xl mb-4">Ils nous font confiance</div>
        <div class="text-white mb-6">Découvrez nos partenaires qui ont fait le choix de choisir LinkUpp.</div>

        <p-carousel [value]="partners" [numVisible]="5" [numScroll]="1" [responsiveOptions]="responsiveOptions"
            [circular]="true">
            <ng-template let-partner pTemplate="item">
                <div class="m-2 p-3 h-full align-content-center">
                    <div class="mb-3">
                        <div class="relative mx-auto w-12rem h-12rem bg-white align-content-center">
                            <img src="assets/images/web/no_pic.png" [alt]="partner.name" width="100" height="100"
                                *ngIf="partner.logoFilePath === null" />
                            <img [src]="getLogoFullUrl(partner.logoFilePath)" [alt]="partner.name" class="w-full"
                                *ngIf="partner.logoFilePath != null" />
                        </div>
                    </div>
                    <div class="mb-3 font-bold text-white white-space-nowrap overflow-hidden text-overflow-ellipsis">
                        {{ partner.name | uppercase }}
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </div>
</div>

<div id="contacts" class="block home-section-4">
    <div class="grid grid-nogutter">
        <div class="col-12 md:col-6 lg:col-5 xl:col-4 pt-5"
            style="background: rgb(2,0,36); background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 61%, rgba(7,7,187,1) 100%);">
            <div class="text-center">
                <div class="text-white font-bold text-3xl mb-4">Contactez-nous</div>
            </div>

            <div class="flex align-content-center flex-wrap" style="min-height: 300px">
                <div class="flex flex-column gap-3 m-3 p-2">
                    <div class="flex">
                        <span>
                            <p-avatar icon="pi pi-envelope" styleClass="mr-2" shape="circle" />
                            <span class="text-white">&nbsp;contact&#64;linkupp.fr</span>
                        </span>
                    </div>
                    <div class="flex">
                        <span>
                            <p-avatar icon="pi pi-phone" styleClass="mr-2" shape="circle" />
                            <span class="text-white">&nbsp;07 68 85 11 05 / 06 13 21 16 01</span>
                        </span>
                    </div>
                    <div class="flex">
                        <span>
                            <p-avatar icon="pi pi-instagram" styleClass="mr-2" shape="circle" />
                            <span class="text-white">&nbsp;Instagram</span>
                        </span>
                    </div>
                    <div class="flex">
                        <span>
                            <p-avatar icon="pi pi-linkedin" styleClass="mr-2" shape="circle" />
                            <span class="text-white">&nbsp;LinkedIn</span>
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-12 md:col-6 lg:col-7 xl:col-8 py-5 px-5 surface-100">
            <div class="flex w-full justify-content-center">
                <form class="w-full md:w-10 lg:w-8 xl:w-6" [formGroup]="contactForm" cl
                    (ngSubmit)="onSubmitContactForm()">
                    <div class="flex flex-column gap-2 mb-2">
                        <label class="font-medium" for="name">Nom et prénom</label>
                        <input pInputText id="name" aria-describedby="name-help" formControlName="name" />
                        <small class="text-red-600" id="name-help"
                            *ngIf="contactForm.controls.name.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 40 caractères.
                        </small>
                    </div>
                    <div class="flex flex-column gap-2 mb-2">
                        <label class="font-medium" for="email">Votre email</label>
                        <input pInputText id="email" aria-describedby="email-help" formControlName="email" />
                        <small class="text-red-600" id="email-help"
                            *ngIf="contactForm.controls.email.errors?.['pattern']">
                            Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                        </small>
                    </div>
                    <div class="flex flex-column gap-2 mb-2">
                        <label class="font-medium" for="object">Object</label>
                        <input pInputText id="object" aria-describedby="object-help" formControlName="object" />
                        <small class="text-red-600" id="object-help"
                            *ngIf="contactForm.controls.object.errors?.['pattern']">
                            Règles: que des caractères alphanumériques, entre 2 et 50 caractères.
                        </small>
                    </div>
                    <div class="flex flex-column gap-2 mb-2">
                        <label class="font-medium" for="message">Message</label>
                        <textarea id="message" aria-describedby="message-help" rows="5" cols="30" pInputTextarea
                            formControlName="message"></textarea>
                        <small class="text-red-600" id="message-help"
                            *ngIf="contactForm.controls.message.errors?.['pattern']">
                            Règles: entre 15 et 255 caractères.
                        </small>
                    </div>
                    <div class="flex justify-content-end flex-row gap-2">
                        <p-button icon="pi pi-times" label="Annuler" [rounded]="true" [outlined]="true"
                            severity="danger" (onClick)="discardContactForm()"
                            *ngIf="contactForm.controls.name.value != null || contactForm.controls.email.value != null || contactForm.controls.object.value != null || contactForm.controls.message.value != null" />
                        <p-button type="submit"
                            icon="pi pi-envelope" label="Envoyer"
                            [loading]="getLoading()"
                            [rounded]="true" [outlined]="true"
                            [disabled]="!contactForm.valid || getLoading()" />
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<p-scrollTop />

<app-footer />
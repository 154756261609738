<div class="block">
    <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">
        <div class="p-2 mb-5 text-sm text-500 font-medium">
            Référence: {{ sponsorshipDetails.reference}}
        </div>

        <div class="flex justify-content-between p-2 mb-8">
            <div class="flex">
                <div class="flex flex-column">
                    <div class="relative mx-auto max-w-5rem md:max-w-10rem">
                        <img src="assets/images/web/no_pic.png" class="w-full"
                            *ngIf="sponsorshipDetails.company?.logoFilePath === null" [alt]="sponsorshipDetails.company?.name" />
                        <img [src]="getLogoFullUrl(sponsorshipDetails.company?.logoFilePath)" class="w-full border-round"
                            *ngIf="sponsorshipDetails.company?.logoFilePath != null" [alt]="sponsorshipDetails.company?.name" />
                    </div>
                    <div class="flex justify-content-center font-bold">
                        {{ sponsorshipDetails.company?.name | uppercase}}
                    </div>
                </div>
            </div>
            <div class="flex" *ngIf="sponsorshipDetails.amount > 0">
                <div class="flex font-bold mb-2">
                    GAINS : {{ sponsorshipDetails.amount }},00 €
                </div>
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5 gap-2">
            <div class="flex font-bold mb-2">
                PARRAIN
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-user"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godFather?.firstName }} {{ sponsorshipDetails.godFather?.lastName }}
                </span>
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-phone"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godFather?.phone }}
                </span>
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-at"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godFather?.email }}
                </span>
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5 gap-2">
            <div class="flex font-bold mb-2">
                FILLEUL
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-user"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godChild?.firstName }} {{ sponsorshipDetails.godChild?.lastName }}
                </span>
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-phone"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godChild?.phone }}
                </span>
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-at"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godChild?.email }}
                </span>
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-map-marker"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.city }}
                </span>
            </div>
        </div>

        <div class="md:flex justify-content-between mb-5">
            <div class="flex flex-column p-2 md:mb-0 mb-5 gap-2">
                <div class="flex font-bold mb-2">
                    COMMERCIAL
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-user"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.commercial?.firstName }} {{ sponsorshipDetails.commercial?.lastName }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-phone"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.commercial?.phone }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-at"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.commercial?.email }}
                    </span>
                </div>
            </div>
            <div class="flex flex-column p-2 md:p-5 gap-2">
                <div class="flex md:justify-content-end font-bold mb-2">
                    SAV
                </div>
                <div class="flex md:justify-content-end gap-2">
                    <span class="block md:hidden">
                        <i class="pi pi-phone"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.company?.phone }}
                    </span>
                    <span class="hidden md:block">
                        <i class="pi pi-phone"></i>
                    </span>
                </div>
                <div class="flex md:justify-content-end gap-2">
                    <span class="block md:hidden">
                        <i class="pi pi-at"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.company?.email }}
                    </span>
                    <span class="hidden md:block">
                        <i class="pi pi-at"></i>
                    </span>
                </div>
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5">
            <div class="flex font-bold mb-2">
                DESCRIPTION DU BESOIN
            </div>
            <div class="flex">
                {{ sponsorshipDetails.description }}
            </div>
        </div>

        <div class="p-2">
            <div class="flex justify-content-between border-bottom-1 mb-5 pb-2">
                <span class="flex font-bold">SUIVI</span>
                <span class="flex font-bold"
                    [ngClass]="sponsorshipDetails.status?.name === 'STATUS_CANCELED' ? 'text-red-600' : 'text-green-400'"
                    *ngIf="activeUser === 'customer' || activeUser === 'admin' || !sponsorshipDetails.updatable">{{ sponsorshipDetails.status?.label }}</span>
                <p-dropdown [options]="processingStatus" optionLabel="label"
                    [placeholder]="sponsorshipDetails.status.label" (onChange)="onChangeStatus($event)" *ngIf="activeUser === 'commercial' && sponsorshipDetails.updatable"></p-dropdown>
            </div>

            <div class="grid mx-5">
                <div [ngClass]="sponsorshipDetails.updatable && (activeUser === 'customer' || activeUser === 'commercial') ? 'col-12 md:col-6' : 'col-12'">
                    <p-timeline [value]="sponsorshipDetails.events">
                        <ng-template pTemplate="content" let-event>
                            <small>{{ event.createdBy }}, {{ event.createdAt | date:'medium' }}</small>
                            <p class="p-text-secondary">{{ event.eventMessage }}</p>
                        </ng-template>
                    </p-timeline>
                </div>
                <div class="col-12 md:col-6" *ngIf="(activeUser === 'customer' || activeUser === 'commercial') && sponsorshipDetails.updatable">
                    <div class="flex justify-content-center">
                        <div class="flex flex-column">
                            <span class="p-float-label">
                                <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="sponsorshipMessageEvent"
                                    maxlength="255"></textarea>
                                <label for="float-input">Note (255 caractères max).</label>
                            </span>
                            <p-button class="text-right"
                                icon="pi pi-save" [loading]="getLoading()" label="Ajouter"
                                [rounded]="true" [outlined]="true" (onClick)="onClickAddNote()"
                                [disabled]="sponsorshipMessageEvent.length === 0 || getLoading()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

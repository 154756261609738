export class NewCustomerRequest {

  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  commercialId: number | null;

  constructor(firstName: string | null, lastName: string | null, email: string | null, phone: string | null, commercialId: number | null) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.commercialId = commercialId;
  }

}